<template>
  <section>
    <div class="home-sub-title text-center">
      <p>5501 Adams ST Unit C Matteson, IL 60443</p>
    </div>
    <div class="home-welcome text-center">
      <h2>About Us</h2>
    </div>
  </section>
  <section class="second-section mt-5">
    <div class="container">
      <div class="row">
        <div class="col-8 mx-auto">
          <p class="mb-3">
            E & A Truck Sales is strategically set up to give customers more
            attention and better service with well-trained mechanics, quality
            equipment, open-honest customer relations, a belief in standing
            behind our product all while maintaining the best prices in the
            market.
          </p>
          <p class="mb-3">
            We serve first time buyers as well as large fleets, whichever
            category you are in, we have options to help your company grow.
          </p>
          <p class="mb-3">
            All of us at E & A Truck Sales will work enthusiastically to earn
            your business and exceed your expectations.
          </p>
        </div>
      </div>
    </div>
  </section>
  <base-footer></base-footer>
</template>

<script>
export default {};
</script>

<style scoped>
.read {
  font-size: 1.3rem;
}
.read a {
  font-weight: 700;
  color: #ff0004;
}
.video-wrap {
  width: 100%;
}
@media screen and (max-width: 576px) {
  p {
    font-size: 0.9rem;
  }
}
</style>
